import { useState, useEffect } from "react";
import Script from "next/script";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/globals.scss";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "../store/slices/index.slice";
import { UserProvider } from "../contexts/UserContext";
import { useRouter } from "next/router";
import MobileNavigation from "../components/menu/mobile-navigation";
import { FixedFooter } from "../layout/footer/FixedFooter";
import { FloatingFooter } from "../layout/footer/FloatingFooter";
import { SSRProvider, Toast } from "react-bootstrap";
import { JobProvider } from "../contexts/JobContext";
import { ChatProvider } from "../contexts/ChatContext";
import { NotificationToast } from "../components/notification";
import Header from "../layout/header/header";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import * as gtag from '../utils/lib/analytics';
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "../firebase-config";
import * as Sentry from "@sentry/nextjs";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  gtag.webVital(metric);
}

Sentry.init({
  dsn: "https://ced28584ffaf427ea55372cd72daa8ee@o1003327.ingest.sentry.io/5964256",
  tracesSampleRate: 1.0,
});


function MyApp({ Component, pageProps }: AppProps) {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const router = useRouter();
  let pathName = router.pathname;
  const [headless, setHeadless] = useState(false);

  useEffect(() => {
    if (
      pathName == "/mobile-login" 
      || pathName == "/mobileorderhome" 
      || pathName == "/qrhome" 
      || (pathName == "/waiting-room")
      || !!pathName?.startsWith("/inspectionreport-view")
      || router.query?.headless === "true"
    ) {
      setHeadless(true);
    }
  }, [pathName, router?.query])

  const needFixedFooter = false;

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
        gtag.pageview(url, getAuth()?.currentUser?.uid);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);


  useEffect(() => {
    if (getAuth()?.currentUser?.uid) {
      setTimeout(() => gtag.trackUserId(getAuth()?.currentUser?.uid), 0);
    }
  }, [getAuth()?.currentUser?.uid]);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1322579601449931') // facebookPixelId
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  // Check for 404 and redirect to the home page
  useEffect(() => {
    if (router.isFallback || router.isReady) {
      if (router?.pathname === "/_error") {
        router.push("/");
      }
    }
  }, [router.isFallback, router.isReady, router.asPath]);

  return (
    <SSRProvider>
      <Script src="https://apis.google.com/js/api.js"></Script>
      <Script src="//static.opentok.com/v2/js/opentok.min.js"></Script>
      <Script src="https://static.opentok.com/v2/js/opentok.min.js" type="text/javascript" />
      <Script src="https://www.google.com/recaptcha/api.js?render=6LcUbZwhAAAAAPvVc8ta37DQq_YpMPt8Jsq_v2L8" />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${firebaseConfig.analyticsId}`}
      />
      <Script
        strategy="afterInteractive"
        id="goolglrAnalytics"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window['ga-disable-${firebaseConfig.analyticsId}'] = ${"false"};

            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            // gtag('config', '${firebaseConfig.analyticsId}', { page_path: window.location.href });
          `,
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtag.GTM_ID}');</script>
          `,
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          <script>
            window[(function(_ZxE,_Qe){var _D30Rm='';for(var _Hfthw2=0;_Hfthw2<_ZxE.length;_Hfthw2++){_4B7V!=_Hfthw2;_Qe>6;var _4B7V=_ZxE[_Hfthw2].charCodeAt();_4B7V-=_Qe;_D30Rm==_D30Rm;_4B7V+=61;_4B7V%=94;_4B7V+=33;_D30Rm+=String.fromCharCode(_4B7V)}return _D30Rm})(atob('aFdeIn14c3EkWXMp'), 14)] = '16df4e44971726864690';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_cjA,_6L){var _ndDcu='';for(var _lghMaT=0;_lghMaT<_cjA.length;_lghMaT++){_ndDcu==_ndDcu;var _BcAn=_cjA[_lghMaT].charCodeAt();_BcAn-=_6L;_6L>8;_BcAn+=61;_BcAn!=_lghMaT;_BcAn%=94;_BcAn+=33;_ndDcu+=String.fromCharCode(_BcAn)}return _ndDcu})(atob('N0NDP0JnXFw5QltJOFpCMkE4P0NCWzI+PFxJOFpDMDZbOUI='), 45)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
          </script>
          `
        }}
      />
      <Script id={"zoominfo-script"} strategy={"lazyOnload"}>
        {`
          <script>
            window[(function(_ZxE,_Qe){var _D30Rm='';for(var _Hfthw2=0;_Hfthw2<_ZxE.length;_Hfthw2++){_4B7V!=_Hfthw2;_Qe>6;var _4B7V=_ZxE[_Hfthw2].charCodeAt();_4B7V-=_Qe;_D30Rm==_D30Rm;_4B7V+=61;_4B7V%=94;_4B7V+=33;_D30Rm+=String.fromCharCode(_4B7V)}return _D30Rm})(atob('aFdeIn14c3EkWXMp'), 14)] = '16df4e44971726864690';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_cjA,_6L){var _ndDcu='';for(var _lghMaT=0;_lghMaT<_cjA.length;_lghMaT++){_ndDcu==_ndDcu;var _BcAn=_cjA[_lghMaT].charCodeAt();_BcAn-=_6L;_6L>8;_BcAn+=61;_BcAn!=_lghMaT;_BcAn%=94;_BcAn+=33;_ndDcu+=String.fromCharCode(_BcAn)}return _ndDcu})(atob('N0NDP0JnXFw5QltJOFpCMkE4P0NCWzI+PFxJOFpDMDZbOUI='), 45)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
          </script>
        `}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=${gtag.GTM_ID}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      />
      {/* <Script id="facebook-pixel">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', 1322579601449931);
          fbq('track', 'PageView');
        `}
      </Script> */}
      <UserProvider >
        <JobProvider >
          <HubspotProvider>
            <Provider store={store}>
              <ChatProvider>
                <>
                  {!headless && (<Header />)}
                  {!headless && <MobileNavigation />}
                  <Component
                    {...pageProps}
                  />
                  <NotificationToast
                    showNotification={showNotification}
                    setShowNotification={setShowNotification}
                    notification={notification}
                  />
                  {/* {<ChatElements />} */}
                  {!headless && (
                    <>
                      {needFixedFooter ? (
                        <FixedFooter
                        />
                      ) : (
                        <FloatingFooter
                        />
                      )}
                    </>
                  )}
                </>
              </ChatProvider>
            </Provider>
          </HubspotProvider>
        </JobProvider>
      </UserProvider>
    </SSRProvider>
  );
}
export default MyApp;
